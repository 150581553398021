<template>
  <div>
    <title-header :title="title" :buttons="[]"></title-header>

    <v-form v-model="validData">
      <v-container class="ml-8">
        <v-col cols="12" md="5" sm="8">
          <v-text-field outlined label="Nombre" v-model="me.displayName" :rules="rules.name"></v-text-field>
          <v-text-field
            outlined
            label="Correo electrónico"
            :rules="rules.email"
            v-model="me.email">
          </v-text-field>

          <v-switch v-model="changePassword" label="Cambiar contraseña"></v-switch>
          <v-text-field
            outlined
            label="Nueva contraseña"
            v-model="newPassword"
            :type="showPassword ? 'text' : 'password'"
            :rules="rules.password"
            v-if="changePassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword">
          </v-text-field>

          <p class="ml-2 mb-5 mt-2">*Campos obligatorios</p>

          <v-list disabled>
            <v-subheader>Grupos</v-subheader>
            <v-list-item-group v-model="group" color="primary">
              <template v-for="(group, i) in me.groups">
                <v-list-item :key="i">
                  <v-list-item-icon>
                    <v-icon v-text="'mdi-account-group'"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="group.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="i"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>

        </v-col>

        <div class="mt-5 mb-20">
          <v-btn class="mr-6" rounded outlined color="primary" width="10rem" dark @click="cancel()">Cancelar</v-btn>
          <v-btn
            rounded
            color="primary"
            width="10rem"
            @click="updateClicked()"
            :disabled="!validData"
          >Actualizar</v-btn>
        </div>
      </v-container>
    </v-form>
    <v-dialog v-model="dialog" persistent max-width="480">
      <v-card>
      <v-card-title class="headline">Felicidades {{me.displayName}}</v-card-title>
      <v-card-text>Tu perfil de usuario se ha actualizado satisfactoriamente.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="cancel()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar color="primary" v-model="showSnackbar">
      {{snackBarText}}
      <v-btn text @click="showSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TitleHeader from '@/components/app/title-header/title-header'
import rules from '@/constants/input-validation'
import {
  SESSION_GET_ME,
  USERS_UPDATE_USER
} from '@/store/actions.type'

export default {
  name: 'my-profile',
  props: {},
  data() {
    return {
      title: 'Mi perfil',
      showPassword: false,
      changePassword: false,
      showSnackbar: false,
      snackbarTimeout: 1000,
      newPassword: '',
      snackBarText: '',
      dialog: false,
      validData: false,
      rules
    }
  },
  computed: {
    ...mapGetters({
      me: 'getSessionUser'
    })
  },
  methods: {
    ...mapActions({
      getMe: SESSION_GET_ME,
      updateUser: USERS_UPDATE_USER
    }),
    cancel() {
      this.$router.push('/settings/users')
    },
    updateClicked() {
      if (this.newPassword !== '' && this.changePassword) {
        this.me.password = this.newPassword
      }
      this.updateUser(this.me)
        .then(response => {
          this.dialog = true
        })
        .catch(e => {
          this.snackBarText = e.message
          this.showSnackbar = true
        })
    }
  },
  mounted() {
    this.getMe()
  },
  components: {
    TitleHeader
  }
}
</script>

<style>
p {
  font-size: 13px;
}
</style>
